.console {
  height: 100%;
  font-size: 0.9em;
}

.input,
.output {
  width: auto;
  height: 100%;
  overflow-y: scroll;
}
