body,
pre {
  font-family: "Courier New", Courier, monospace;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header,
.subhead {
  font-family: "Courier New", Courier, monospace;
  text-align: center;
  font-weight: normal;
  margin-bottom: 0;
}

.subhead {
  font-size: 0.9em;
}

.subhead a {
  color: slateblue;
  text-decoration: none;
}

sl-divider {
  margin-bottom: 0;
}

.sidebar {
  font-family: Arial, Helvetica, sans-serif;
  width: 20%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  border-right: 1px solid #ccc;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.sidebar-main {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  flex-grow: 1;
}

.connection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.console {
  width: 80%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  overflow-x: hidden;
  z-index: 0;
}
