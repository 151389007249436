.input {
  height: 100%;
}

.editor {
  width: 100%;
  height: 90%;
  background-color: #f5f5f5;
}

.editor textarea:focus {
  outline: none;
}

.controls {
  background-color: #fff;
  height: 10%;
  text-align: center;
  padding-top: 1em;
  border-top: 1px solid #ccc;
}

.controls sl-menu-item::part(:global(base)) {
  padding: 0em 1em;
}
