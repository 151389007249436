.connection {
  width: 90%;
  margin: 0.2rem 0.5rem;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: 1em;
}

.connection::part(:global(body)):hover {
  background-color: #f5f5f5;
}

.connection::part(:global(body)) {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.connection.state-error::part(:global(base)) {
  border-color: firebrick;
}

.chainName {
  font-weight: bold;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.connection.state-error .chainName {
  font-weight: normal;
}

.connection.state-connecting .chainName {
  font-weight: normal;
}

.settings {
  flex-shrink: 0;
  margin-top: 0.1em;
}

.stateIcon {
  margin-top: 0.1em;
  margin-right: 0.5em;
}

.connection.state-error .stateIcon {
  color: firebrick;
}

.settings::part(:global(base)):hover {
  color: darksalmon;
}
