.section {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  font-weight: bold;
  margin: 0.5rem;
  margin-left: 1rem;
}

.address {
  width: 90%;
  margin: 0.2rem 0.5rem;
  cursor: pointer;
}

.address.selected::part(:global(base)) {
  background-color: black;
  color: white;
  border-radius: 0.25em;
}

.address.selected::part(:global(body)):hover {
  background-color: #555555;
}

.address::part(:global(body)):hover {
  background-color: #f5f5f5;
}

.address::part(:global(body)) {
  padding: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
