.adder {
  width: 90%;
  margin: 0.2rem 0.5rem;
  cursor: pointer;
}

.adder::part(:global(body)):hover {
  background-color: #f5f5f5;
}

.adder::part(:global(body)) {
  padding: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.plus::part(:global(base)) {
  margin-top: 0.2rem;
}

.dialog {
  z-index: 100;
}

.importGroup {
  display: flex;
  margin-bottom: 0.5rem;
  margin-top: -1em;
}

.address {
  flex-grow: 1;
}

.address::part(:global(base)) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.importButton::part(:global(base)) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.generate::part(:global(base)) {
  margin-left: -0.5em;
  margin-right: -0.5rem;
}

.file {
  border: 1px solid #ccc;
  padding: 0.6em;
  border-radius: 0 0.3em 0 0;
  margin-left: -1px;
  width: 14em;
  flex-grow: 1;
}

.code {
  flex-grow: 1;
  margin-left: -1px;
  width: 4em;
}

.code::part(:global(base)) {
  border-radius: 0 0.3em 0 0;
}

.code input {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}  

.label {
  flex-grow: 1;
}

.label::part(:global(base)) {
  border-radius: 0.3em 0 0 0;
}

.msgWrapper {
  min-height: 10em;
  max-height: 20em;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-top: 0;
}

.instantiateMsg {
  min-height: 10em;
    border: 0;
}

.instantiateMsg textarea {
  min-height: 10em;
  outline: none;
}

.uploadHeader {
  margin: 1em 0;
  display: flex;
  justify-content: space-between;
}

.uploadTitle {
  font-size: 1.25rem;
}

.choices {
  margin-top: -0.1em;
  margin-left: 1em;
}

.choice {
  display: inline;
}

.choice::part(:global(base)) {
  padding:0.4em;
  font-size: 0.9em;
}


.uploadGroup {
  display: flex;
}

.uploadButton {
  margin-top: 1em;
  display: flex;
  flex-direction: row-reverse;
}
