.section {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  font-weight: bold;
  margin: 0.5rem;
  margin-left: 1rem;
}
