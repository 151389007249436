.accountCard {
  width: 90%;
  margin: 0.2rem 0.5rem;
  cursor: pointer;
}

.accountCard::part(:global(body)):hover {
  background-color: #f5f5f5;
}

.accountCard::part(:global(body)) {
  padding: 0.5rem;
}

.accountCard .main,
.accountCard .details {
  display: flex;
  justify-content: space-between;
}

.accountCard .details {
  margin-top: 0.5em;
  font-size: 0.8em;
}

.accountCard .details .balance {
  margin-left: 1em;
  color: #88c;
}

.accountCard .label {
  font-weight: bold;
}

.accountCard .address {
  color: #bbb;
  cursor: pointer;
}

.accountCard .address:hover {
  color: #ddd;
}

.accountCard .label,
.accountCard .address {
  overflow: hidden;
  text-overflow: ellipsis;
}

.accountCard.selected::part(:global(base)) {
  background-color: black;
  color: white;
}

.accountCard.selected::part(:global(body)):hover {
  background-color: black;
  cursor: default;
}

.accountCard.disabled::part(:global(base)) {
  color: #999;
  cursor: not-allowed;
}

.accountCard.disabled .label {
  font-weight: normal;
}

.accountCard .left {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.accountCard sl-icon {
  cursor: pointer;
}

.close {
  contain: none;
  flex-shrink: 0;
  margin-left: 0.5em;
}

.close::part(:global(base)) {
  margin-top: 0.1rem;
}

.close::part(:global(base)):hover {
  color: lightcoral;
}

.dialog {
  z-index: 100;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 8rem;
  margin-top: -1rem;
  margin-bottom: 0.5rem;
}

.generate::part(:global(base)) {
  margin-left: -0.5em;
  margin-right: -0.5rem;
}
